import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'

const lang = localStorage.getItem('lang') || 'ru';
axios.defaults.baseURL = 'https://form-hawaii.test-vip.kz/api/';
axios.defaults.params = {
    'lang': lang,
  };

createApp(App).use(store).use(router).mount('#app')
