<template>
  <div class="home">
    <div class="home__content">
      <div class="home__items">
        <div v-if="showTest" class="home__items-test">
          <div class="t">
            <div v-for="(item, idx) in testData" :key="'A' + idx">
              <div
                class="t__content"
                v-if="
                  item.connected_answer_id === null ||
                  checkConnectedId(item.connected_answer_id)
                "
              >
                <div class="t__content-title">
                  <p>{{ item.question }}</p>
                  <span v-if="item.type === 'rating'" class="subtitle"
                    >( 1 - очень плохо, 10 - отлично )<br /><br
                  /></span>
                </div>
                <div v-if="item.type === 'checkboxes'">
                  <div
                    class="t__content-radio"
                    v-for="(item2, idx2) in item.answers"
                    :key="'B' + idx2"
                  >
                    <input
                      type="checkbox"
                      :id="item2.id"
                      :value="{ id: item2.id, value: item2.answer }"
                      v-model="values[item.id].value"
                    />
                    <label :for="item2.id">{{ item2.answer }}</label>
                    <br />
                  </div>
                  <span
                    v-if="item.required === 1 && !values[item.id]?.value"
                    style="color: red"
                    :style="{ display: error ? 'block' : 'none' }"
                    >Обязательное поле</span
                  >
                </div>
                <div v-if="item.type === 'select'">
                  <div
                    class="t__content-radio"
                    v-for="(item2, idx2) in item.answers"
                    :key="'B' + idx2"
                  >
                    <input
                      type="radio"
                      :id="item2.id"
                      :name="item.id"
                      @change="newArray(item2), checkConnectedId(item2.id)"
                      :value="{ id: item2.id, value: item2.answer }"
                      v-model="values[item.id]"
                    />
                    <label :for="item2.id">{{ item2.answer }}</label>
                    <br />
                  </div>
                  <div class="t__content-radio" v-if="item.hasOther === 1">
                    <input
                      ref="myInputRef"
                      type="radio"
                      :id="item.id + '1'"
                      :name="item.id"
                      :checked="values[item.id]?.other"
                      :value="{ id: null, other: true, value: '' }"
                      v-model="values[item.id]"
                    />
                    <label :for="item.id + '1'">Другое</label><br />
                    <input
                      style="width: 100%; min-height: 40px"
                      v-if="values[item.id]?.other"
                      type="text"
                      v-model="values[item.id].value"
                    />
                  </div>
                  <span
                    v-if="item.required === 1 && !values[item.id]?.value"
                    style="color: red"
                    :style="{ display: error ? 'block' : 'none' }"
                    >Обязательное поле</span
                  >
                </div>
                <div v-else-if="item.type === 'rating'">
                  <div class="t__content-range">
                    <div class="mydict">
                      <div>
                        <label v-for="item3 in 10" :key="'C' + item3.id">
                          <input
                            type="radio"
                            :name="'radio' + item.id"
                            :value="{ id: null, value: item3 }"
                            v-model="values[item.id]"
                          />
                          <span>{{ parseInt(item3) }}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <span
                    class="spanerror"
                    v-if="item.required === 1 && !values[item.id]?.value"
                    style="color: red"
                    :style="{ display: error ? 'block' : 'none' }"
                    >Обязательное поле</span
                  >
                </div>
                <!-- <div v-if="item.type === 'title'">
                  <p class="subtitle">{{ item.question }}2</p>
                </div> -->
                <div v-if="item.type === 'textarea'">
                  <input
                    type="textarea"
                    v-model="values[item.id].value"
                  /><br />
                  <span
                    class="spanerror"
                    v-if="item.required === 1 && !values[item.id]"
                    style="color: red"
                    :style="{ display: error ? 'block' : 'none' }"
                    >Обязательное поле</span
                  >
                </div>
                <div v-if="item.type === 'date'">
                  <input type="date" v-model="values[item.id].value" /><br />
                  <span
                    class="spanerror"
                    v-if="item.required === 1 && !values[item.id].value"
                    style="color: red"
                    :style="{ display: error ? 'block' : 'none' }"
                    >Обязательное поле</span
                  >
                </div>
                <div v-if="item.type === 'email'">
                  <input type="email" v-model="values[item.id].value" /><br />
                  <span
                    class="spanerror"
                    v-if="
                      item.required === 1 && !isEmail(values[item.id].value)
                    "
                    style="color: red"
                    :style="{ display: error ? 'block' : 'none' }"
                    >Обязательное поле</span
                  >
                </div>
              </div>
            </div>
            <div class="t__button">
              <button
                class="btn_blue_outline"
                v-if="$route.query.page > 1"
                @click="prevPage()"
              >
                Предыдущий
              </button>
              <button
                class="btn_blue"
                v-if="$route.query.page < totalPage"
                @click="nextPage()"
              >
                Следующий
              </button>
              <button class="btn_blue" v-else @click="finishTest()">
                Завершить
              </button>
            </div>
          </div>
        </div>
        <div v-else-if="finish" class="home__items-finish">
          <span
            ><svg
              width="210"
              height="210"
              viewBox="0 0 210 210"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M60 120L90.75 144.6C91.5758 145.28 92.5429 145.768 93.5807 146.027C94.6186 146.287 95.7013 146.311 96.75 146.1C97.8087 145.901 98.8123 145.477 99.6924 144.856C100.572 144.235 101.308 143.431 101.85 142.5L150 60"
                stroke="#00FF0A"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M105 202.5C158.848 202.5 202.5 158.848 202.5 105C202.5 51.1522 158.848 7.5 105 7.5C51.1522 7.5 7.5 51.1522 7.5 105C7.5 158.848 51.1522 202.5 105 202.5Z"
                stroke="#00FF0A"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p v-html="text2"></p>
          <button class="btn_blue" @click="toMain()">На главную</button>
        </div>
        <div v-else class="home__items-welcome">
          <p v-html="text"></p>
          <button class="btn_blue" @click="startTest()">Начать опрос</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      text: 'Дорогой Гость,<br/><br/>Мы благодарим за Ваш выбор и доверие к Аквапарку Hawaii, Miami aquapark & SPA. Мы надеемся, что Вам понравилось недавнее посещение. Для нас очень ценно Ваше мнение, спасибо, что уделили время, чтобы пройти опрос.<br/><br/>Опрос состоит из вопросов с несколькими вариантами ответов, где Вы всегда можете оставить свои дополнительные комментарии и рекомендации. Это займет всего пару минут и поможет нам понять, где и как в дальнейшем мы можем улучшить наш сервис. <br/><br/>Нажимая кнопку "Отправить" в конце опроса, Вы соглашаетесь на сбор, обработку и хранение персональных данных в соответствии с <a href="#">Политикой конфиденциальности</a>. <br/><br/>Благодарим за Ваше участие,<br/><br/> Ваш Аквапарк Hawaii, Miami Aquapark & SPA',
      text2: "Спасибо за прохождение опроса!<br/> Ваше мнение важно для нас",
      showTest: false,
      picked: [],
      page: 1,
      testData: [],
      totalPage: 0,
      error: false,
      range: "",
      finish: false,
      values: {},
      otherInput: [],
    };
  },
  created() {
    this.FETCH_TEST();
    if (this.$route.query.start === "true") {
      this.showTest = true;
    }
    if (!this.$route.query.page) {
      this.$router.push({
        query: { page: this.page, start: this.$route.query.start },
      });
    }
  },
  methods: {
    startTest() {
      this.$router.push({ query: { start: true, page: parseInt(this.page) } });
    },
    checkConnectedId(value) {
      for (const key in this.values) {
        if (this.values[key].id == value) {
          return true;
        }
      }
      return false;
    },
    isEmail(text) {
      // Паттерн для проверки email
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

      // Проверяем текст на соответствие паттерну
      return emailPattern.test(text);
    },
    FETCH_TEST() {
      axios
        .get("questions", {
          params: {
            page: this.$route.query.page,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            console.log("asdasd", response.data.total_page);
            this.totalPage = response.data.total_page;
            this.testData = response.data.questions;
            this.testData.forEach((item) => {
              if (!this.values.hasOwnProperty(item.id)) {
                this.values[item.id] =
                  item.type === "checkboxes"
                    ? { id: null, value: [] }
                    : { id: null, value: "" };
              }
            });
          } else {
            console.log(response);
          }
        })
        .catch((e) => {
          // alert(e.response.data?.message);
        });
    },
    nextPage() {
      if (document.querySelector(".spanerror") !== null) {
        this.error = true;
      } else {
        if (this.$route.query.page < this.totalPage) {
          this.$router.push({
            query: {
              page: parseInt(this.$route.query.page) + 1,
              start: this.$route.query.start,
            },
          });
        }
        this.error = false;
      }
    },
    prevPage() {
      if (parseInt(this.$route.query.page) > 1) {
        this.$router.push({
          query: {
            page: parseInt(this.$route.query.page) - 1,
            start: this.$route.query.start,
          },
        });
      }
    },
    finishTest() {
      this.showTest = false;
      // this.$router.push({ name: 'home' })
      let sendArray = [];
      let data = this.values;
      for (const key in data) {
        if (Array.isArray(data[key].value)) {
          data[key].value.forEach((item) => {
            sendArray.push({
              question_id: parseInt(key),
              answer_id: item.id,
              answer: item.value,
            });
          });
        } else if (data[key].id === null && data[key].value === "") {
        } else {
          sendArray.push({
            question_id: parseInt(key),
            answer_id: data[key].id,
            answer: data[key].value,
          });
        }
      }
      axios
        .post("submit", { answers: sendArray })
        .then((res) => {
          this.finish = true;
          this.values = {};
          localStorage.removeItem("values");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toMain() {
      window.location.href = "https://hawaii.kz/";
    },
    newArray(value) {
      console.log("newArray", value);
    },
  },
  mounted() {
    this.values = JSON.parse(localStorage.getItem("values")) || {};
  },
  watch: {
    values: {
      deep: true, // This watches for nested changes
      handler(newVal, oldVal) {
        localStorage.setItem("values", JSON.stringify(this.values));
      },
    },
    $route() {
      if (this.$route.query.start === "true") {
        this.showTest = true;
      }
      if (this.$route.query.page) {
        this.FETCH_TEST();
        // this.page = this.$route.query.page
      }
    },
  },
};
</script>

<style lang="scss">
.t {
  &__content {
    .subtitle {
      color: #212529;
      font-family: "Roboto";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      // line-height: 28.8px;
      // padding-bottom: 40px;
    }
    &-title {
      p {
        color: #212529;
        font-family: "Roboto";
        font-size: 19.2px;
        font-style: normal;
        font-weight: 700;
        line-height: 28.8px;
      }
    }

    &-radio {
      label {
        color: #212529;
        font-family: "Roboto";
        font-size: 19.2px;
        font-style: normal;
        font-weight: 400;
        line-height: 28.8px;
        cursor: pointer;
      }
    }

    &-range {
      :focus {
        outline: 0;
        border-color: #2260ff;
        box-shadow: 0 0 0 4px #b5c9fc;
      }

      .mydict div {
        // display: flex;
        // flex-wrap: wrap;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(1rem, 1fr));
        margin-top: 0.5rem;

        // justify-content: center;
        @media (max-width: 576px) {
          // display: grid;
          // grid-template-columns: repeat(auto-fit, minmax(1rem, 1fr));
          // justify-content: unset;
        }
      }

      .mydict input[type="radio"] {
        clip: rect(0 0 0 0);
        clip-path: inset(100%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
      }

      .mydict input[type="radio"]:checked + span {
        // box-shadow: 0 0 0 0.0625em #0043ed;
        // // background-color: #dee7ff;
        z-index: 1;
        // color: #0043ed;
        transform: scale(1.2);
      }

      label span {
        display: block;
        cursor: pointer;
        background-color: #fff;
        padding: 0.175em 1.275em;
        position: relative;
        margin-left: 0.0625em;
        box-shadow: 0 0 0 0.0625em #b5bfd9;
        letter-spacing: 0.05em;
        text-align: center;
        transition: background-color 0.5s ease;
        color: #fff;
        font-family: "Roboto";
        font-size: 19.2px;
        font-style: normal;
        font-weight: 400;
        line-height: 28.8px;

        @media (max-width: 576px) {
          padding: 5px 5px;
        }
      }

      label {
        &:nth-child(1) {
          span {
            background-color: #5e0000;
          }
        }

        &:nth-child(2) {
          span {
            background-color: #930000;
          }
        }

        &:nth-child(3) {
          span {
            background-color: #c70000;
          }
        }

        &:nth-child(4) {
          span {
            background-color: #e01407;
          }
        }

        &:nth-child(5) {
          span {
            background-color: #e15233;
          }
        }

        &:nth-child(6) {
          span {
            background-color: #ea6216;
          }
        }

        &:nth-child(7) {
          span {
            background-color: #ffb800;
          }
        }

        &:nth-child(8) {
          span {
            background-color: #a2cc2b;
          }
        }

        &:nth-child(9) {
          span {
            background-color: #64e737;
          }
        }

        &:nth-child(10) {
          span {
            background-color: #24ff00;
          }
        }

        &:first-child span {
          border-radius: 0.375em 0 0 0.375em;
        }

        &:last-child span {
          border-radius: 0 0.375em 0.375em 0;
        }
      }
    }
  }

  &__button {
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin-top: 40px;
    justify-content: center;

    @media (max-width: 576px) {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  }
}

.btn_blue {
  position: relative;
  border-radius: 10px;
  background: #3899ec;
  padding: 16px 90px;
  border: 1px solid #3899ec;
  color: #ffffff;
  font-family: "Roboto";
  font-size: 18.2px;
  font-style: normal;
  font-weight: 400;
  line-height: 28.8px;

  @media (max-width: 576px) {
    padding: 10px 25px;
  }

  &:hover {
    background: rgb(61, 106, 255);
    box-shadow: 0 0 30px 5px rgba(0, 142, 236, 0.815);
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
  }

  &:hover::before {
    -webkit-animation: sh02 0.5s 0s linear;
    -moz-animation: sh02 0.5s 0s linear;
    animation: sh02 0.5s 0s linear;
  }

  &::before {
    content: "";
    display: block;
    width: 0px;
    height: 86%;
    position: absolute;
    top: 7%;
    left: 0%;
    opacity: 0;
    background: #fff;
    box-shadow: 0 0 50px 30px #fff;
    -webkit-transform: skewX(-20deg);
    -moz-transform: skewX(-20deg);
    -ms-transform: skewX(-20deg);
    -o-transform: skewX(-20deg);
    transform: skewX(-20deg);
  }

  @keyframes sh02 {
    from {
      opacity: 0;
      left: 0%;
    }

    50% {
      opacity: 1;
    }

    to {
      opacity: 0;
      left: 100%;
    }
  }

  &:active {
    box-shadow: 0 0 0 0 transparent;
    -webkit-transition: box-shadow 0.2s ease-in;
    -moz-transition: box-shadow 0.2s ease-in;
    transition: box-shadow 0.2s ease-in;
  }
}

.btn_blue_outline {
  position: relative;
  border-radius: 10px;
  background: transparent;
  padding: 16px 90px;
  border: 1px solid #3899ec;
  color: #3899ec;
  font-family: "Roboto";
  font-size: 18.2px;
  font-style: normal;
  font-weight: 400;
  line-height: 28.8px;

  @media (max-width: 576px) {
    padding: 10px 25px;
  }

  &:hover {
    // background: rgb(61, 106, 255);
    // box-shadow: 0 0 30px 5px rgba(0, 142, 236, 0.815);
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
  }

  &:hover::before {
    -webkit-animation: sh02 0.5s 0s linear;
    -moz-animation: sh02 0.5s 0s linear;
    animation: sh02 0.5s 0s linear;
  }

  &::before {
    content: "";
    display: block;
    width: 0px;
    height: 86%;
    position: absolute;
    top: 7%;
    left: 0%;
    opacity: 0;
    background: #fff;
    box-shadow: 0 0 50px 30px #fff;
    -webkit-transform: skewX(-20deg);
    -moz-transform: skewX(-20deg);
    -ms-transform: skewX(-20deg);
    -o-transform: skewX(-20deg);
    transform: skewX(-20deg);
  }

  @keyframes sh02 {
    from {
      opacity: 0;
      left: 0%;
    }

    50% {
      opacity: 1;
    }

    to {
      opacity: 0;
      left: 100%;
    }
  }

  &:active {
    box-shadow: 0 0 0 0 transparent;
    -webkit-transition: box-shadow 0.2s ease-in;
    -moz-transition: box-shadow 0.2s ease-in;
    transition: box-shadow 0.2s ease-in;
  }
}

.home {
  background: no-repeat top url("@/assets/images/backgroundv3desktop.png");
  min-height: 100vh;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-top: 420px;

  @media (max-width: 576px) {
    background: no-repeat top url("@/assets/images/backgroundv3mobile.png");
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    padding-top: 200px;
    padding-bottom: 45px;
  }

  // padding: 420px auto;
  &__content {
    max-width: 900px;
    padding: 77px 100px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 0px 150px 0px #ffffff;
    margin: 0 auto;

    @media (max-width: 576px) {
      padding: 20px 25px;
      margin: 0 25px;
      border-radius: 10px;
    }
  }

  &__items {
    &-welcome {
      display: flex;
      justify-content: center;
      flex-direction: column;
      // align-content: center;
      align-items: center;
      gap: 20px;

      p {
        color: #212529;
        font-family: "Roboto";
        font-size: 18.2px;
        font-style: normal;
        font-weight: 400;
        line-height: 28.8px;
        // text-shadow: #808080 1px 0 10px;

        a {
          color: #f00000 !important;
          list-style: none;
          text-decoration: none;
        }
      }
    }

    &-finish {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-content: center;
      align-items: center;
      gap: 20px;
      text-align: center;

      @media (max-width: 576px) {
        span {
          svg {
            width: 100px !important;
            height: 100px !important;
          }
        }
      }

      p {
        color: #212529;
        font-family: "Roboto";
        font-size: 18.2px;
        font-style: normal;
        font-weight: 400;
        line-height: 28.8px;
        // text-shadow: #808080 1px 0 10px;

        a {
          color: #f00000 !important;
          list-style: none;
          text-decoration: none;
        }
      }
    }
  }
}
</style>
