<template>
    <div class="header">
        <div class="header__content">
            <div class="header__content-title">
                <img src="@/assets/images/hawaii-logo.svg" alt="">
                <h1>Опрос удовлетворенности клиента</h1>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.header {
    padding: 8px 0;
    background-color: #007BFF;

    &__content {

        &-title {
            display: flex;
            flex-direction: row;
            gap: 14px;
            justify-content: center;
            align-content: center;
            margin: 7px 0;

            h1 {
                color: #FFF;
                font-family: 'Roboto';
                font-size: 19px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px;
            }
        }
    }
}
</style>